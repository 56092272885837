<template>
  <div class="subscribeList">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          placeholder="请输入地址名称"
          v-model="searchParam.openUrlName"
          label="地址名称"
        ></v-input>
        <v-select
          clearable
          :options="selectionListOps"
          v-model="searchParam.tags"
          @change="$refs.list.search()"
          :placeholder="'请选择'"
          label="标签"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="删除"
          type="text"
          v-if="scope.row.canEdit"
          @click="deleteUrl(scope.row.id)"
        />
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="editUrl(scope.row.id)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getSubscribeListUrl,
  delSubscribeListUrl,
  subscribeVerifyUrl,
} from "./api.js";
import {
  verifyAuditStatus,
  verifyAuditStatusMap,
  selectionListOps,
  selectionListMap,
} from "./map";

export default {
  name: "subscribeList",
  data() {
    return {
      isdialog: false,
      verifyAuditStatus,
      id: "",
      searchParam: {
        openUrlName: "",
        tags: "",
      },
      form: {
        auditStatus: 2,
        auditRemarks: "",
      },
      communitList: [],
      tableUrl: getSubscribeListUrl,
      headers: [
        {
          prop: "id",
          label: "接口ID",
        },
        {
          prop: "openUrl",
          label: "url地址",
        },
        {
          prop: "openUrlName",
          label: "url地址名称",
        },
        {
          prop: "limitPerDay",
          label: "调用次数上限",
        },
        {
          prop: "tag",
          label: "标签",
          formatter: (row, prop) => {
            return selectionListMap[row[prop]] || "--";
          },
        },
      ],
      selectionListOps: selectionListOps(),
    };
  },
  components: {},
  mounted() {},
  methods: {
    editUrl(id) {
      this.$router.push({
        name: "addSubscribe",
        query: {
          id: id,
        },
      });
    },
    toAdd() {
      this.$router.push({
        name: "addSubscribe",
      });
    },
    deleteUrl(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: id,
        };
        this.$axios
          .post(`${delSubscribeListUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.subscribeList {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 10px 0;
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
